import React, {useEffect, useState} from 'react';
import Slider from "react-slick";

const Testimonial = () => {
    const [avtarBg, setAvtarBg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/testimonial-bg.png)`
    });

    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/testimonial-bg.png)`
    });

    const SlickArrowRight = ({currentSlide, slideCount, ...props}) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1}
            type="button"
        >
            <img className="owl-next" src={`${process.env.PUBLIC_URL}/assets/images/next.png`} alt="next-arrow"/>
        </button>
    );

    const SlickArrowLeft = ({currentSlide, slideCount, ...props}) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0}
            type="button"
        >
            <img className="owl-prev" src={process.env.PUBLIC_URL + "/assets/images/back.png"} alt="back-arrow"/>
        </button>
    );
    // Slick slider Option for Testimonial
    const options = {
        arrows: true,
        infinite: true,
        dots: true,
        appendDots: (dots) => (
            <span className=".blog-carousel.owl-theme .owl-dots .owl-dot.active span">
                {dots}
            </span>
        ),
        prevArrow: <SlickArrowLeft/>,
        nextArrow: <SlickArrowRight/>,
        marginRight: 30,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    margin: 0,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],
    };

    // Dynamic Testimonial Easy to Update
    let data = [
        {
            name: "david myers",
            designation: "founder",
            photo: `${process.env.PUBLIC_URL}/assets/images/landingImg/testimonial-1.png`,
            description: "I created Famdoc to help keep track of and organize important documentation and evidence for Family Court. I am a single parent who has personally lived through the immense court battles and Documentation is everything. If Famdoc can help one family, then it has served its purpose."
        }
    ];

    // Dynamic Testimonial Data Loop
    let DataList = data.map((val, i) => {
        return (
            <div className="testimonial-item" key={i}>
                <div className="testimonial-block">
                    <div className="testimonial-avtar" style={avtarBg}>
                        <img src={val.photo} alt=""/>
                    </div>
                    <div className="testimonial-text">
                        <p>{val.description}</p>
                        <h3>{val.name}</h3>
                        <h6>{val.designation}</h6>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <section id="/testimonial" className="testimonial" style={bgImg}>
            <div className="testimonial-decor">
                <div className="testi-circle1">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial2.png`} alt=""/>
                </div>
                <div className="testi-circle2">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial1.png`} alt=""/>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <Slider
                            {...options}
                            className="testimonial-carousel"
                        >
                            {DataList}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;
