import React, {useEffect, useState} from 'react';
import {
    Heading,
    Container,
    Spinner,
    Flex,
    Text,
} from '@chakra-ui/react';
import axios from "../api/axios";
import Blog from "../components/Blog/Blog";
import Header from "../components/Navbar/Header";
import Footer from "../components/Navbar/Footer";
import SEO from "../components/SEO/SEO";


const BlogListing = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const controller = new AbortController();

    const fetchBlogs = async () => {
        setLoading(true);
        try {
            const resp = await axios.get('/api/v1/blogs/public/', {signal: controller.signal});
            setBlogs(resp.data.results);
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchBlogs().then();
    }, []);


    return (
        <>
            <SEO title={'FamDoc blogs'} description={'FamDoc blog listings'} type={'website'} />
            <Header/>
            <Container maxW={'7xl'} p="12" rowGap={3} minH={'100vh'}>
                <Heading as="h1">FamDoc Blogs</Heading>
                {
                    loading && <Spinner
                        position={'absolute'}
                        top={'50%'}
                        left={'50%'}
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='fam.100'
                        color='fam.800'
                        size='xl'
                    />
                }
                {
                    (!loading && blogs.length === 0) && (
                        <Flex mt={30} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                            <img
                                width="150"
                                src={`${process.env.PUBLIC_URL}/assets/images/not-found.gif`}
                                alt="no-data"
                            />
                            <Text fontSize={'20'} fontWeight={'700'} color={'fam.800'}>No Data!</Text>
                        </Flex>
                    )
                }
                {
                    blogs.map(item => <Blog key={item.id} {...item}/>)
                }
            </Container>
            <Footer/>
        </>
    );
};

export default BlogListing;
