import React from 'react';
import {Helmet} from "react-helmet-async";

const SEO = ({
                 title,
                 description,
                 image = 'https://famdoc.cloud/assets/images/logo.png',
                 name = 'FamDoc',
                 type = 'website'
             }) => {
    return (
        <Helmet prioritizeSeoTags={true}>
            <title>{title}</title>
            <meta name={'description'} content={description}/>
            <meta name="msapplication-TileImage" content={image}/>
            {/* Facebook Tags */}
            <meta property={'og:type'} content={type}/>
            <meta property={'og:title'} content={title}/>
            <meta property={'og:description'} content={description}/>
            <meta property="og:image" content={image}/>
            <meta property="og:url" content="https://famdoc.cloud"/>
            {/* Twitter Tags */}
            <meta name={'twitter:creator'} content={name}/>
            <meta name={'twitter:card'} content={type}/>
            <meta name={'twitter:title'} content={title}/>
            <meta name={'twitter:description'} content={description}/>
        </Helmet>
    );
};

export default SEO;
