import React, {useEffect, useState} from 'react';
import {Link as RichLink, useParams} from "react-router-dom";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {Button, Container, Spinner} from "@chakra-ui/react";
import axios from "../api/axios";
import Blog from "../components/Blog/Blog";
import Footer from "../components/Navbar/Footer";
import SEO from "../components/SEO/SEO";

const BlogDetails = () => {
    const {blogSlug} = useParams();
    const [blog, setBlog] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        const fetchBlog = async () => {
            setLoading(true);
            try {
                const resp = axios.get(`/api/v1/blogs/public/${blogSlug}/`, {signal: controller.signal});
                setBlog((await resp).data);
            } catch (e) {

            } finally {
                setLoading(false);
            }
        }
        fetchBlog().then();
        return () => {
            controller.abort();
        };
    }, []);


    return (
        <>
            <SEO title={blog?.title} description={blog?.description} image={blog?.image} type={'article'} />
            <Button
                    leftIcon={<AiOutlineArrowLeft/>}
                    variant={'ghost'}
                    colorScheme={'teal'}
                    _hover={{bg: 'transparent', color: 'fam.800'}}
                    as={RichLink}
                    to={'/blogs'}
                >
                    Back To Blogs
                </Button>
            <Container maxW={'7xl'} p="10" rowGap={5}>
                {
                    loading && <Spinner
                        position={'absolute'}
                        top={'50%'}
                        left={'50%'}
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='fam.100'
                        color='fam.800'
                        size='xl'
                    />
                }
                {blog?.id && <Blog showContent={true} {...blog} />}
            </Container>
            <Footer/>
        </>
    );
};

export default BlogDetails;
