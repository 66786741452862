import {BabyApp, DocAdd, DocumentFolder, ExpandDown, Notebook, PeopleSafe, Stethoscope} from "@icon-park/react";

export const icons = {
    "access-events": ExpandDown,
    "child-support": BabyApp,
    "doctor-information": Stethoscope,
    "legal-documentation": DocumentFolder,
    "police-report": PeopleSafe,
    "support-numbers": DocAdd,
    "miscellaneous-documents": Notebook
}