import React, {useEffect, useState} from 'react';
import Slider from "react-slick";

const Team = () => {
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/team-img-bg.png)`
    });

    const [teamBg, setTeamBg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/team-bg.png)`
    });

    // Slick slider Option for Team Members
    const options = {
        arrows: false,
        accessibility: false,
        infinite: true,
        dots: true,
        appendDots: (dots) => (
            <span className=".blog-carousel">
        {dots}
      </span>
        ),
        marginRight: 30,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // Dynamic Team Members Easy to Update
    let data = [
        {
            name: "sohaib",
            designation: "developer",
            photo: `${process.env.PUBLIC_URL}/assets/images/team/team-s.webp`,
            facebook: "#",
            google: "#",
            twitter: "#",
            instagram: "#",
            rss: "#",
        },
        {
            name: "bilal",
            designation: "devloper",
            photo: `${process.env.PUBLIC_URL}/assets/images/team/team-bilal.jpeg`,
            facebook: "#",
            google: "#",
            twitter: "#",
            instagram: "#",
            rss: "#",
        },
        {
            name: "Abdur Rehman",
            designation: "developer",
            photo: `${process.env.PUBLIC_URL}/assets/images/team/team-ab.jpeg`,
            facebook: "#",
            google: "#",
            twitter: "#",
            instagram: "#",
            rss: "#",
        },
        {
            name: "Arqum Mubeen",
            designation: "designer",
            photo: `${process.env.PUBLIC_URL}/assets/images/team/team-d.jpeg`,
            facebook: "#",
            google: "#",
            twitter: "#",
            instagram: "#",
            rss: "#",
        }
    ];

    // Dynamic Team Members Data Loop
    let DataList = data.map((val, i) => {
        return (
            <div className="team-item d-flex" style={{marginRight: "30px"}} key={i}>
                <div className="team-block" style={bgImg}>
                    <div className="team-box">
                        <div className="team-avtar">
                            <img src={val.photo} alt=""/>
                        </div>
                        <div className="team-text">
                            <h3>{val.name}</h3>
                            <h6>{val.designation}</h6>
                        </div>
                        <div className="overlay">
                            <ul className="team-social">
                                <li>
                                    <a href={val.facebook}>
                                        <i className="fa fa-facebook"/>
                                    </a>
                                </li>
                                <li>
                                    <a href={val.google}>
                                        <i className="fa fa-google-plus"/>
                                    </a>
                                </li>
                                <li>
                                    <a href={val.twitter}>
                                        <i className="fa fa-twitter"/>
                                    </a>
                                </li>
                                <li>
                                    <a href={val.instagram}>
                                        <i className="fa fa-instagram"/>
                                    </a>
                                </li>
                                <li>
                                    <a href={val.rss}>
                                        <i className="fa fa-rss"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <section id="/team" className="team" style={teamBg}>
            <div className="team-decor">
                <div className="team-circle1">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/team1.png`} alt=""/>
                </div>
                <div className="team-circle2">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/team3.png`} alt=""/>
                </div>
                <div className="team-circle3">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/team.png`} alt=""/>
                </div>
            </div>
            <div className="container">
                <div className="row ">
                    <div className="col-sm-12">
                        <h2 className="title">
                            our <span>smart team</span>
                        </h2>
                    </div>
                    <div className="col-sm-12">
                        <div>
                            <Slider
                                {...options}
                                className="team-carousel rounded-dots slick-margin"
                            >
                                {DataList}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Team;
