import React from 'react';
import {Avatar, Box, Heading, HStack, Icon, Image, Link, Tag, Text, useColorModeValue} from "@chakra-ui/react";
import {Link as RichLink} from "react-router-dom";
import {getDate} from "../../api/config";
import {AiOutlineComment} from "react-icons/ai";


const BlogTags = (props) => {
    return (
        <HStack spacing={2} marginTop={props.marginTop}>
            {props.tags.map((tag) => {
                return (
                    <Tag size={'md'} variant="solid" colorScheme="green" key={tag}>
                        {tag}
                    </Tag>
                );
            })}
        </HStack>
    );
};

export const BlogAuthor = (props) => {
    return (
        <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
            <Avatar/>
            <Text color={'fam.800'} fontWeight="medium">{props.name}</Text>
            <Text>—</Text>
            <Text>{props.date}</Text>
        </HStack>
    );
};

const Blog = ({
                  slug,
                  image,
                  category_name,
                  total_comments,
                  title,
                  description,
                  content,
                  user,
                  created_at,
                  showContent
              }) => {
    return (
        <>
            <Box
                marginTop={{base: '1', sm: '5'}}
                display="flex"
                flexDirection={{base: 'column', sm: 'row'}}
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    position="relative"
                    alignItems="center">
                    <Box
                        width={{base: '100%', sm: '85%'}}
                        zIndex="2"
                        marginLeft={{base: '0', sm: '5%'}}
                        marginTop="5%">
                        <Link as={RichLink} to={'/blogs/' + slug} textDecoration="none"
                              _hover={{textDecoration: 'none'}}>
                            <Image
                                borderRadius="lg"
                                src={image}
                                alt="some good alt text"
                                objectFit="contain"
                            />
                        </Link>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box
                            bgGradient={useColorModeValue(
                                'radial(orange.600 1px, transparent 1px)',
                                'radial(orange.300 1px, transparent 1px)'
                            )}
                            backgroundSize="20px 20px"
                            opacity="0.4"
                            height="100%"
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flex="1"
                    flexDirection="column"
                    justifyContent="center"
                    marginTop={{base: '3', sm: '0'}}>
                    <BlogTags tags={[category_name]}/>
                    <Heading
                        fontSize={'24px'}
                        fontWeight={700}
                        _hover={{textDecoration: 'none', color: 'black'}}
                        as={RichLink} to={'/blogs/' + slug}
                        letterSpacing={0.2} marginTop="1"
                    >
                        {title}
                    </Heading>
                    <Text
                        as="p"
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="md">
                        {description}
                    </Text>
                    <BlogAuthor name={user} date={getDate(created_at)} total_comments={total_comments}/>
                </Box>

            </Box>
            {
                showContent && (
                    <Box
                        marginTop={{base: '1', sm: '5'}}
                        className={'blog-detail'} dangerouslySetInnerHTML={{__html: content}}
                    />
                )
            }
        </>
    );
};
export default Blog;
