import React, {useEffect} from 'react';
import {Box, Flex, Image, UnorderedList, ListItem, Text} from '@chakra-ui/react';
import {useDropzone} from "react-dropzone";
import useDocument from "../../hooks/use.document";

const FileUploader = () => {
    const {setFiles} = useDocument();
    const {getRootProps, acceptedFiles} = useDropzone();

    useEffect(() => {
        setFiles(acceptedFiles);
        return () => {
            setFiles([]);
        };
    }, [acceptedFiles]);


    const files = acceptedFiles.map(file => <ListItem key={file.path}>{file.path}</ListItem>);
    return (
        <Box w={'100%'}>
            <Flex border={1} borderColor={'fam.50'} borderStyle={'dashed'} p={3} boxShadow={'sm'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} {...getRootProps({className: 'dropzone'})}>
                <Image h={'100px'} objectFit={'contain'} src={process.env.PUBLIC_URL + "/assets/images/upload.gif"} />
                <Text color={'fam.800'} fontSize={'18'} fontWeight={'700'}>Select Files To Upload</Text>
            </Flex>
            <UnorderedList mt={3}>
                {files}
            </UnorderedList>
        </Box>
    );
};

export default FileUploader;
