import React from 'react';
import {Avatar, Button, Menu, MenuButton, MenuDivider, MenuItem, MenuList} from "@chakra-ui/react";
import {Link as RichLink} from "react-router-dom";
import {EditIcon} from "@chakra-ui/icons";
import {AiOutlineLogout, AiOutlineFile} from "react-icons/ai";

const hoverProps = {color: 'fam.800', bg: 'fam.100'}
const UserMenu = () => {

    return (
        <Menu>
            <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar bg={'fam.800'} size={'xs'}/>
            </MenuButton>
            <MenuList>
                <MenuItem icon={<EditIcon/>} as={RichLink} to={'/change-password'} _hover={hoverProps}>
                    Change Password
                </MenuItem>
                <MenuItem icon={<AiOutlineFile/>} as={RichLink} to={'/my-blogs'} _hover={hoverProps}>
                    My Blogs
                </MenuItem>
                {/*<MenuItem>Link 2</MenuItem>*/}
                <MenuDivider/>
                <MenuItem icon={<AiOutlineLogout/>} as={RichLink} to={'/logout'} _hover={hoverProps}>
                    Logout
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default UserMenu;
