import React, {useEffect, useState} from 'react';
import {Button, Flex, Spacer, Text, Box, Spinner, Icon} from "@chakra-ui/react";
import useCategories from "../hooks/use.categories";
import {useParams, Link} from "react-router-dom";
import useAxiosPrivate from "../hooks/use.axios-private";
import DocRenderer from "./DocRenderer";
import useDocument from "../hooks/use.document";
import {savePDF} from "@progress/kendo-react-pdf";
import {FiPrinter} from "react-icons/fi";
import {icons} from "../api/icon-mapps";
import {Help} from "@icon-park/react";

const DocDetails = () => {
    const {categoryId, documentId} = useParams();
    const {getCategoryByKey} = useCategories();
    const {document: doc} = useDocument();
    const axiosPrivate = useAxiosPrivate();
    const category = getCategoryByKey(categoryId);
    const [docs, setDocs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [generating, setGenerating] = useState(false);

    const controller = new AbortController();

    const fetchFiles = async () => {
        try {
            setLoading(true);
            const resp = await axiosPrivate.get(`/api/v1/famdoc/files/?document=${documentId}`, {signal: controller.signal});
            setDocs(resp.data.results);
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchFiles().then();
        return () => {
            if (loading) controller.abort()
            setDocs([])
        };
    }, [documentId]);

    const generatePDF = async () => {
        setGenerating(true)
        setTimeout(function () {
            setGenerating(false)
        }, 3000);
        const content = document.getElementById('save-pdf');
        savePDF(content, {
            paperSize: 'a4',
            fileName: 'famdoc.pdf',
            margin: 10
        })
    };


    return (
        <>
            <Flex bg={'fam.100'} p={4} borderRadius={'md'} alignItems={'bold'}>
                <Text
                    alignItems={'center'}
                    as={Link}
                    to={`/docs/${categoryId}`}
                    color={'fam.800'}
                    _hover={{color: 'fam.800'}}
                    fontWeight={'700'}
                    fontSize={'20'}
                >
                    <Icon as={icons[category?.key] ? icons[category.key] : Help} mr={2} />{category?.name}
                </Text>
                <Spacer/>
                <Button
                    leftIcon={<Icon as={FiPrinter}/>}
                    size={'sm'}
                    isLoading={generating}
                    loadingText='Generating..'
                    colorScheme={'teal'}
                    onClick={generatePDF}
                >
                    Print PDF
                </Button>
            </Flex>
            <Box mt={5}>
                {
                    loading && <Spinner
                        position={'absolute'}
                        top={'50%'}
                        left={'50%'}
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='fam.100'
                        color='fam.800'
                        size='xl'
                    />
                }
                <DocRenderer docs={docs} document={doc}/>
            </Box>
        </>
    );
};

export default DocDetails;
