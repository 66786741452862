import React, {useEffect, useState} from 'react';
import {Alert} from "reactstrap";

const Contact = () => {
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/contact.png)`
    });

    const [show, setShow] = useState(false);

    const handleFormChange = (key, event) => {
        // contactUs[key] = event.target.value;
        // setContactUs({...contactUs, key: event.target.value});
    }

    const saveMessage = (e) => {
        e.preventDefault();
        const target = e.target;
        // axios.post(config.baseHttpUrl + "/api/v1/contact-us/", contactUs).then(resp => {
        //     setShow(true);
        //     target.reset();
        //     setTimeout(() => {
        //         setShow(false);
        //         setContactUs({})
        //     }, 4000);
        // })
    }

    return (
        <section id="/contact" className="contact" style={bgImg}>
            <div className="contact-decor">
                <div className="contact-circle1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/main-banner12.png"} alt="main-banner12.png"/>
                </div>
                <div className="contact-circle2">
                    <img src={process.env.PUBLIC_URL + "/assets/images/main-banner1.png"} alt="main-banner1.png"/>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-8 col-sm-12">
                        <div className="contact-us">
                            <div className="w-100">
                                <h2 className="title">
                                    <span>contact </span>information
                                </h2>

                                <h3>
                                    <span>support@marketect.ca</span>
                                </h3>
                                <form className="theme-form" onSubmit={saveMessage}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 md-fgrup-margin">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="your name"
                                                    required="required"
                                                    onChange={event => handleFormChange("name", event)}
                                                />
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="phone"
                                                    required="required"
                                                    onChange={event => handleFormChange("phone", event)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="email address"
                                            required="required"
                                            onChange={event => handleFormChange("email", event)}
                                        />
                                    </div>
                                    <div className="form-group">
                    <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        placeholder="message"
                        required="required"
                        onChange={event => handleFormChange("message", event)}
                    />
                                    </div>
                                    {
                                        show === true && (
                                            <Alert className="pt-3 pb-0" variant="success">
                                                <p>
                                                   <span className="font-weight-bolder text-success">Success!</span> We will contact you shortly.
                                                </p>
                                            </Alert>
                                        )
                                    }
                                    <div className="form-button">
                                        <button
                                            type="submit"
                                            className="btn btn-custom theme-color"
                                        >
                                            send
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 d-medium-none-lg">
                        <div className="contact-right">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/landingImg/3.png`}
                                className="img-fluid"
                                alt="3.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
