import React, {useEffect, useState} from 'react';
import {
    Flex,
    Spacer,
    Text,
    Button,
    Box,
    Spinner,
    TableContainer,
    Table,
    TableCaption,
    Thead,
    Tbody,
    Tr,
    Td,
    Th,
    Icon,
    useDisclosure
} from '@chakra-ui/react';
import {Link, useParams, useNavigate} from "react-router-dom";
import useCategories from "../hooks/use.categories";
import useAxiosPrivate from "../hooks/use.axios-private";
import {getDate} from "../api/config";
import DocModal from "../components/DocModal/DocModal";
import useDocument from "../hooks/use.document";
import {FiPlus, FiList, FiEdit} from "react-icons/fi";
import {BsImages} from "react-icons/bs";
import {icons} from "../api/icon-mapps";
import {Help} from "@icon-park/react";

const DocListing = () => {
    const {categoryId} = useParams();
    const {getCategoryByKey} = useCategories();
    const category = getCategoryByKey(categoryId);
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate();
    const {setDocument, setFiles} = useDocument();
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [docs, setDocs] = useState([]);
    const [loading, setLoading] = useState(false);

    const controller = new AbortController()

    const fetchDocs = async () => {
        try {
            setLoading(true);
            const resp = await axiosPrivate.get(`/api/v1/famdoc/documents/?category=${category.id}`, {signal: controller.signal});
            setDocs(resp.data.results);
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchDocs().then();
        return () => {
            if (loading) controller.abort();
            setDocs([]);
        };
    }, [categoryId]);

    const onEdit = doc => {
        setDocument(doc);
        onOpen();
    }

    const onModalClose = () => {
        setDocument({});
        setFiles([]);
        onClose();
        fetchDocs().then();
    }

    const goToDetails = (item, page='details') => {
        setDocument(item);
        navigate('/docs/' + category.key + `/${page}/` + item.id, {replace: true});
    }


    return (
        <>
            <Flex
                bg={'fam.100'}
                p={4}
                borderRadius={'md'}
                alignItems={'center'}
                boxShadow={'md'}
            >
                <Text color={'fam.800'} fontWeight={'700'} fontSize={'20'}>
                    <Icon as={icons[category?.key] ? icons[category.key] : Help} mr={2}/> {category?.name}
                </Text>
                <Spacer/>
                <Button size={'sm'} leftIcon={<Icon as={FiPlus}/>} colorScheme={'teal'} onClick={onOpen}>
                    Add New
                </Button>
                <DocModal
                    title={category?.name}
                    category={category?.id}
                    onClose={onModalClose}
                    isOpen={isOpen}
                    isLoading={loading}
                />
            </Flex>
            <Box
                position={'relative'}
                minH={'350px'}
                overflow={'hidden'}
            >
                {
                    loading && <Spinner
                        position={'absolute'}
                        top={'50%'}
                        left={'50%'}
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='fam.100'
                        color='fam.800'
                        size='xl'
                    />
                }

                <TableContainer mt={'32px'} whiteSpace={'normal'}>
                    <Table size='sm' variant='simple'>
                        {
                            (!loading && docs.length === 0) && (
                                <TableCaption>
                                    <Flex
                                        width={'100%'}
                                        justifyContent={'center'}
                                        flexDirection={'column'}
                                        alignItems={'center'}
                                    >
                                        <img
                                            width="150"
                                            src={`${process.env.PUBLIC_URL}/assets/images/not-found.gif`}
                                            alt="no-data"
                                        />
                                        <Text fontSize={'20'} fontWeight={'700'} color={'fam.800'}>No Data!</Text>
                                    </Flex>
                                </TableCaption>
                            )
                        }
                        <Thead bgGradient="linear(to-l, fam.800, fam.900)">
                            <Tr>
                                <Th color={'white'}>Date Created</Th>
                                <Th color={'white'}>Name</Th>
                                <Th color={'white'}>Notes</Th>
                                <Th color={'white'}>Files</Th>
                                <Th color={'white'}>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {docs?.map(item => {
                                return (
                                    <Tr key={item.id}>
                                        <Th whiteSpace={'wrap'} scope='row'>{getDate(item.created_at)}</Th>
                                        <Td whiteSpace={'wrap'}>{item.name}</Td>
                                        <Td whiteSpace={'wrap'}>{item.notes}</Td>
                                        <Td>{item.files.length}</Td>
                                        <Td>
                                            <Flex overflow={'hidden'} width={'100%'} columnGap={'1'}>
                                                <Button
                                                    leftIcon={<Icon as={FiEdit}/>}
                                                    size={'xs'}
                                                    colorScheme={'orange'}
                                                    onClick={event => onEdit(item)}
                                                    variant={'ghost'}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    leftIcon={<Icon as={BsImages}/>}
                                                    size={'xs'}
                                                    colorScheme={'blue'}
                                                    _hover={{
                                                        color: 'blue.800',
                                                        background: 'blue.100'
                                                    }}
                                                    variant={'ghost'}
                                                    onClick={event => goToDetails(item)}
                                                >
                                                    View Images
                                                </Button>
                                                <Button
                                                    leftIcon={<Icon as={FiList}/>}
                                                    size={'xs'}
                                                    colorScheme={'blue'}
                                                    _hover={{
                                                        color: 'blue.800',
                                                        background: 'blue.100'
                                                    }}
                                                    variant={'ghost'}
                                                    onClick={event => goToDetails(item, "preview")}
                                                >
                                                    View All Docs
                                                </Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
};

export default DocListing;
