import React, {useEffect, useState} from 'react';
import {Link as RichLink, useLocation, useNavigate} from "react-router-dom";
import {Button, FormControl, FormErrorMessage, Input, Box, Heading, IconButton, Link} from "@chakra-ui/react";
import useAuth from "../hooks/use.auth";
import {useForm} from "react-hook-form";
import axios from "../api/axios";
import {AiOutlineArrowLeft} from "react-icons/ai";

const Login = () => {

    const [showPwd, setShowPwd] = useState(false);

    const {auth, setAuth} = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location?.state?.from?.pathname || '/'

    const {register, handleSubmit, setError, formState: {errors}} = useForm();
    const onSubmit = async data => {
        try {
            const response = await axios.post('/accounts/token/', data);
            localStorage.setItem('refresh', response.data.refresh);
            setAuth(response.data);
            navigate(from, {replace: true})
        } catch ({response}) {
            if (response.status === 401) {
                setError('username', {message: response.data.detail, type: "server"})
            }
        }
    }

    useEffect(() => {
        if (auth?.refresh) navigate(from, {replace: true})
    }, []);


    return (
        <Box as={'div'} className={'bg-wrapper'}>
            <Button
                leftIcon={<AiOutlineArrowLeft/>}
                variant={'ghost'}
                colorScheme={'teal'}
                _hover={{bg: 'transparent', color: 'fam.800'}}
                as={RichLink}
                to={'/'}
            >
                Back To Home
            </Button>
            <section
                className="authentication-form"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/aut-bg.jpg)`
                }}
            >
                <Box as={'div'} className="innerpage-decor">
                    <div className="innerpage-circle1">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial2.png`} alt=""/>
                    </div>
                    <div className="innerpage-circle2">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial1.png`} alt=""/>
                    </div>
                </Box>
                <Box>
                    <Heading letterSpacing={0.2} fontSize={20} as={'h2'} className="title text-center">
                        Login
                    </Heading>
                    <p className="text-center">
                        Welcome to FamDoc, Please Login with your personal account information.
                    </p>
                    <Box boxShadow={'dark-lg'} as={'div'} className="card">
                        <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <FormControl isInvalid={errors.username}>
                                    <Input
                                        type='email'
                                        className={'form-control'}
                                        placeholder={'Email'}
                                        {...register('username', {required: true, maxLength: 100})}
                                    />
                                    <FormErrorMessage>
                                        {
                                            errors.username?.type === 'required' ?
                                                'This field is required' :
                                                errors.username?.type === 'maxLength' ?
                                                    'This field should be less than or equal to 100 characters' :
                                                    errors.username?.message
                                        }
                                    </FormErrorMessage>
                                </FormControl>
                            </div>
                            <div className="form-group">
                                <FormControl isInvalid={errors.password}>
                                    <Input
                                        type={showPwd ? "text" : "password"}
                                        className={'form-control'}
                                        placeholder={'Password'}
                                        {...register('password', {required: true})}
                                    />
                                    <FormErrorMessage>
                                        {
                                            errors.password?.type === 'required' ? 'This field is required' : null
                                        }
                                    </FormErrorMessage>
                                </FormControl>
                                <Box
                                    as={'div'}
                                    className="show-hide"
                                    style={{top: errors.password ? '34%' : '50%'}}
                                    onClick={() => {
                                        setShowPwd(!showPwd);
                                    }}
                                >
                                    <span className={!showPwd ? "show" : ""}/>
                                </Box>

                            </div>

                            <div className="form-group row custom-row">
                                <div className="col-12">
                                    <div className="custom-control custom-checkbox">
                                        Don't have an account ?
                                        <Button
                                            _hover={{color: 'teal.600'}}
                                            _focus={{color: 'teal.600'}}
                                            as={RichLink}
                                            to={'/register'}
                                            colorScheme='teal'
                                            variant='link'
                                            className="text-right col-6"
                                        >
                                            Sign UP
                                        </Button>
                                    </div>
                                </div>
                                <Link
                                    as={RichLink}
                                    mt={3}
                                    color={'fam.800'}
                                    to={`/forget-password`}
                                    className="col-12 text-center theme-link"
                                >
                                    lost your password?
                                </Link>
                            </div>
                            <div className="form-button text-center">
                                <button
                                    type="submit"
                                    className="btn btn-custom btn-lg theme-color"
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                    </Box>
                </Box>
            </section>
        </Box>
    );
};

export default Login;
