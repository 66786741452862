import React, {createContext, useEffect, useState} from "react";
import useAxiosPrivate from "../hooks/use.axios-private";

const CategoriesContext = createContext({
    categories: [],
    getCategoryByKey: catKey => null
})


export const CategoriesProvider = ({children}) => {
    const axiosPrivate = useAxiosPrivate();
    const [categories, setCategories] = useState([]);


    useEffect(() => {
        const controller = new AbortController();
        const fetchCategories = async () => {
            const resp = await axiosPrivate.get('/api/v1/famdoc/categories/?sort=name', {signal: controller.signal});
            setCategories(resp.data.results);
        }
        fetchCategories().then();
        return () => {
            controller.abort();
        };
    }, [axiosPrivate]);

    const getCategoryByKey = catKey => {
        return categories.filter(item => item.key === catKey)[0]
    }

    const stateValues = {
        categories,
        getCategoryByKey
    }

    return <CategoriesContext.Provider value={stateValues}>{children}</CategoriesContext.Provider>

}

export default CategoriesContext
