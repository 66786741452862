import React from 'react';
import {
    Box,
    Flex,
    HStack,
    IconButton,
    Button,
    useDisclosure,
    Stack,
    Image,
    Icon,
    Link as ChakraLink, useColorModeValue
} from '@chakra-ui/react';
import {HamburgerIcon, CloseIcon} from '@chakra-ui/icons';
import {Link as RichLink} from 'react-router-dom';
import {AiFillDashboard, AiOutlineLogin} from "react-icons/ai";
import useAuth from "../../hooks/use.auth";
import jwtDecode from "jwt-decode";
import UserMenu from "./UserMenu";
import Notifications from "../Notifications/Notifications";


const Links = [
    {displayName: 'Home', targetPath: '/'},
    {displayName: 'About', targetPath: '/about'},
    {displayName: 'Feature', targetPath: '/feature'},
    {displayName: 'Screenshot', targetPath: '/screenshot'},
    {displayName: 'Team', targetPath: '/team'},
    {displayName: 'Testimonial', targetPath: '/testimonial'},
    {displayName: 'Contact Us', targetPath: '/contact'},
    {displayName: 'Blog', targetPath: '/blogs'},
    {displayName: 'FamDoc AI', targetPath: '/famdocai'},
];

const NavLink = ({displayName, targetPath}) => {

    return (
        <ChakraLink
            fontSize={'14px'}
            fontWeight={600}
            whiteSpace={'nowrap'}
            px={1}
            py={1}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                color: 'fam.800',
            }}
            as={RichLink}
            to={targetPath}
            _focus={{color: 'fam.800'}}
            _selected={{color: 'fam.800'}}
        >
            {displayName}
        </ChakraLink>
    );
};


const Header = () => {
    const {auth} = useAuth();
    const user = auth?.refresh ? jwtDecode(auth?.refresh) : {};

    const {isOpen, onOpen, onClose} = useDisclosure();


    return (
        <>
            <Box
                zIndex={3}
                position={"sticky"}
                top={0}
                bg={'whiteAlpha.900'}
                px={4}
                boxShadow={'sm'}
            >
                <Flex
                    h={16}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <IconButton
                        size={'sm'}
                        icon={isOpen ? <CloseIcon/> : <HamburgerIcon/>}
                        aria-label={'Open Menu'}
                        display={{md: 'none'}}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack alignItems={'center'}>
                        <RichLink to={'/'}>
                            <Image
                                w={'50px'}
                                boxSize='50px'
                                objectFit='contain'
                                src={process.env.PUBLIC_URL + '/assets/images/logo.png'}
                            />
                        </RichLink>
                        <HStack
                            as={'nav'}
                            display={{base: 'none', md: 'flex'}}>
                            {Links.map((item, index) => <NavLink key={index} {...item} />)}
                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'} columnGap={2}>
                        {
                            user?.user_id ? (
                                <>
                                    <Button
                                        variant={'solid'}
                                        size={'sm'}
                                        color={'white'}
                                        leftIcon={<AiFillDashboard/>}
                                        as={RichLink}
                                        to={'/dashboard'}
                                        _hover={{bg: '-moz-initial'}}
                                        bgGradient="linear(to-l, fam.800, fam.900)"
                                    >
                                        Dashboard
                                    </Button>
                                    <Notifications/>
                                    <UserMenu/>
                                </>
                            ) : (
                                <>
                                    <Button
                                        _hover={{color: 'teal.600'}}
                                        _focus={{color: 'teal.600'}}
                                        as={RichLink}
                                        to={'/register'}
                                        colorScheme='teal'
                                        variant='link'
                                    >
                                        Register
                                    </Button>
                                    <Button
                                        leftIcon={<AiOutlineLogin/>}
                                        as={RichLink}
                                        to={'/login'}
                                        color={'white'}
                                        _hover={{bg: '-moz-initial'}}
                                        bgGradient="linear(to-l, fam.800, fam.900)"
                                        size={'sm'}
                                    >
                                        Login
                                    </Button>
                                </>
                            )
                        }


                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{md: 'none'}}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((item, index) => <NavLink key={index} {...item} />)}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    );
}

export default Header
