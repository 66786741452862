import React from 'react';
import {Button, Modal, ModalContent, ModalFooter, ModalOverlay} from "@chakra-ui/react";
import DocViewer from "@cyntler/react-doc-viewer";

const FamViewer = ({isOpen, onClose, docs}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
            <ModalOverlay/>
            <ModalContent>
                <DocViewer documents={docs}/>

                <ModalFooter>
                    <Button variant='ghost' colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default FamViewer;
