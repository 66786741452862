import React, {useState} from 'react';
import {Button, Checkbox, useDisclosure, Link, Box} from "@chakra-ui/react";
import {Link as RichLink, useNavigate} from "react-router-dom";
import FamViewer from "../components/FamViewer";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {useForm} from "react-hook-form";
import axios from "../api/axios";
import InputField from "../components/InputField/InputField";


const Register = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [termsAccepted, setTermsAccepted] = useState(true);
    const navigate = useNavigate();
    const {register, handleSubmit, getValues, setError, formState: {errors}} = useForm();
    const onSubmit = async data => {
        try {
            delete data['password_confirm']
            console.log(data)
            const response = await axios.post('/accounts/register/', data);
            navigate('/login', {replace: true})
        } catch ({response}) {
            if (response.status === 400) {
                const {data} = response;
                Object.keys(data).forEach(key => {
                    setError(key, {message: data[key], type: 'server'});
                })
            }
        }
    }

    const signUpForm = (
        <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <InputField
                    name={'email'}
                    type={'email'}
                    placeholder={'email address'}
                    register={register}
                    errors={errors}
                    options={{required: true, maxLength: 100}}
                />
            </div>

            <div className="form-group">
                <InputField
                    name={'first_name'}
                    type={'text'}
                    placeholder={'first name'}
                    register={register}
                    errors={errors}
                    options={{required: true, maxLength: 100}}
                />
            </div>
            <div className="form-group">
                <InputField
                    name={'last_name'}
                    type={'text'}
                    placeholder={'last name'}
                    register={register}
                    errors={errors}
                    options={{required: true, maxLength: 100}}
                />
            </div>
            <div className="form-group">
                <InputField
                    name={'phone_number'}
                    type={'text'}
                    placeholder={'phone number'}
                    register={register}
                    errors={errors}
                    options={{required: false, maxLength: 30}}
                />
            </div>
            <div className="form-group">
                <InputField
                    name={'address'}
                    type={'text'}
                    placeholder={'address'}
                    register={register}
                    errors={errors}
                    options={{required: false, maxLength: 500}}
                />
            </div>
            <div className="form-group">
                <InputField
                    name={'custody'}
                    type={'text'}
                    placeholder={'custody'}
                    register={register}
                    errors={errors}
                    options={{required: false, maxLength: 20}}
                />
            </div>
            <div className="form-group">
                <InputField
                    name={"password"}
                    type={"password"}
                    placeholder={"password"}
                    register={register}
                    errors={errors}
                    options={{required: true, maxLength: 50}}
                />
            </div>
            <div className="form-group">
                <InputField
                    name={"password_confirm"}
                    type={"password"}
                    placeholder={"password confirm"}
                    register={register}
                    errors={errors}
                    options={{
                        required: true,
                        maxLength: 50,
                        validate: {
                            matchesPreviousPassword: value => {
                                const {password} = getValues();
                                return password === value || "Password mismatch!"
                            }
                        }
                    }}
                />
            </div>

            <Checkbox
                isInvalid={!termsAccepted}
                isChecked={termsAccepted}
                onChange={event => setTermsAccepted(event.target.checked)} defaultValue={termsAccepted}
            >
                I accept
            </Checkbox>
            <span
                onClick={onOpen}
                className="cursor-pointer theme-link ml-2"
            >
                terms and conditions
            </span>
            <div className="form-group row custom-row">
                <div className="col-12 text-center">
                    <div className="custom-control custom-checkbox">
                        Already have an account ?
                        <Link
                            as={RichLink}
                            to={'/login'}
                            color={'fam.800'}
                            className="col-12 theme-link"
                        >
                            Login
                        </Link>
                    </div>
                </div>
                <a
                  href={`${process.env.PUBLIC_URL}/forget-password`}
                  className="col-12 text-center theme-link"
                >
                  lost your password
                </a>
            </div>
            <div className="form-button text-center">
                <Button type={"submit"}>REGISTER</Button>
            </div>
        </form>
    )


    const docs = [{uri: require("./privacy_policy_famdoc.pdf")}]

    return (
        <>
            <Button
                leftIcon={<AiOutlineArrowLeft/>}
                variant={'ghost'}
                colorScheme={'teal'}
                _hover={{bg: 'transparent', color: 'fam.800'}}
                as={RichLink}
                to={'/'}
            >
                Back To Home
            </Button>
            <section className="authentication-form"
                     style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/aut-bg.jpg)`}}>
                <div className="innerpage-decor">
                    <div className="innerpage-circle1">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial2.png`} alt=""/>
                    </div>
                    <div className="innerpage-circle2">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial1.png`} alt=""/>
                    </div>
                </div>
                <div>
                    <h2 className="title text-center">
                        Hello<span> Join US</span>
                    </h2>
                    <p className="text-center">
                        Welcome to FamDoc, Please join us with your personal account
                        information.
                    </p>
                    <Box as={'div'} boxShadow={'dark-lg'} className="card">
                        {signUpForm}
                    </Box>
                </div>
                <FamViewer isOpen={isOpen} onClose={onClose} docs={docs}/>
            </section>
        </>
    );
};

export default Register;
