import {useEffect} from 'react';
import useAuth from "./use.auth";
import {axiosPrivate} from "../api/axios";
import useRefreshToken from "./use.refresh-token";
import {useNavigate} from "react-router-dom";

const useAxiosPrivate = () => {
    const {auth, setAuth} = useAuth();
    const refresh = useRefreshToken();
    const navigate = useNavigate();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config?.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth.access}`;
                }
                return config;
            }, error => Promise.reject(error)
        )
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const access = await refresh();
                    if (!access) {
                        setAuth({})
                        localStorage.clear();
                        navigate('/', {replace: true})
                    }
                    prevRequest.headers['Authorization'] = `Bearer ${access}`;
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        )
        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, [auth, refresh, navigate]);


    return axiosPrivate;
};

export default useAxiosPrivate;
