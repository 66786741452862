import React, {useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    IconButton,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    Popover,
    PopoverTrigger,
    PopoverArrow,
    Spacer,
    Textarea,
    VStack, Divider,
    useToast
} from "@chakra-ui/react";
import {BiMessageRoundedError} from 'react-icons/bi';
import {GrCopy, GrDocumentPdf} from 'react-icons/gr';
import Header from "../components/Navbar/Header";
import Footer from "../components/Navbar/Footer";
import {useForm} from "react-hook-form";
import axios from "../api/axios";
import {savePDF} from "@progress/kendo-react-pdf";
import ReactQuill from "react-quill";

const FamDocAI = () => {
    const {register, handleSubmit, reset, formState: {errors}} = useForm();
    const toast = useToast();
    const [answer, setAnswer] = useState(undefined);
    const [loading, setLoading] = useState(false);


    const onSubmit = async data => {
        setLoading(true);
        try {
            const resp = await axios.post('api/v1/chat-gpt/', data);
            setAnswer(resp.data.answer);
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    const clearAll = () => {
        reset();
        setAnswer(undefined);
    }

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(answer);
        toast({
            title: 'Copied',
            description: answer,
            status: 'success',
            duration: 3000,
            isClosable: true,
        })
    }

    const saveToPDF = () => {
        const content = document.getElementsByClassName('ql-editor')[0];
        savePDF(content, {
            paperSize: 'a4',
            fileName: 'famdocai.pdf',
            margin: 10
        })
    }

    return (
        <Box>
            <Header/>
            <VStack py={'24'} px={4} minH={'100vh'} bg={'fam.400'}>
                <Heading>FamDoc AI</Heading>
                <VStack
                    spacing={4}
                    maxW={'lg'}
                    w={'full'}
                    as={'form'}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormControl>
                        <HStack w={'full'}>
                            <FormLabel>
                                Question
                            </FormLabel>
                            <Spacer/>
                            <Popover placement='bottom-end'>
                                <PopoverTrigger>
                                    <IconButton variant={'ghost'} size={'md'} icon={<BiMessageRoundedError/>}/>
                                </PopoverTrigger>
                                <PopoverContent p={4} zIndex={1}>
                                    <PopoverArrow/>
                                    <PopoverCloseButton/>
                                    <PopoverBody>
                                        AI`s responses do not replace legal advice and that any forms or information
                                        gathered from the AI should be reviewed by a lawyer.
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </HStack>
                        <Textarea
                            bg={'white'}
                            rows={5}
                            {...register('question', {required: true})}
                        />
                    </FormControl>
                    <HStack w={'full'}>
                        <Button
                            size={'sm'}
                            variant={'ghost'}
                            bg={'white'}
                            _hover={{bg: 'white'}}
                            onClick={clearAll}
                        >
                            Clear
                        </Button>
                        <Spacer/>
                        <Button
                            size={'sm'}
                            type={"submit"}
                            color={'white'}
                            _hover={{bg: '-moz-initial'}}
                            bgGradient="linear(to-l, fam.800, fam.900)"
                            isLoading={loading}
                        >
                            Submit
                        </Button>
                    </HStack>
                    <Divider borderWidth={1} borderColor={'fam.900'}/>
                </VStack>
                {
                    answer && (
                        <VStack spacing={2} w={'full'} maxWidth={'lg'}>
                            <HStack
                                w={'full'}
                            >
                                <Heading as={'h4'}>Answer</Heading>
                                <Spacer/>
                                <IconButton
                                    onClick={copyToClipBoard}
                                    variant={'ghost'}
                                    size={'sm'}
                                    aria-label={'copy-clipboard'}
                                    icon={<GrCopy/>}
                                    color={'white'}
                                    _hover={{bg: '-moz-initial'}}
                                    bgGradient="linear(to-l, fam.800, fam.900)"
                                />
                                <IconButton
                                    onClick={saveToPDF}
                                    variant={'ghost'}
                                    size={'sm'}
                                    aria-label={'to-pdf'}
                                    icon={<GrDocumentPdf/>}
                                    color={'white'}
                                    _hover={{bg: '-moz-initial'}}
                                    bgGradient="linear(to-l, fam.800, fam.900)"
                                />
                            </HStack>
                            <ReactQuill value={answer} theme="snow"/>
                        </VStack>
                    )
                }
            </VStack>
            <Footer/>
        </Box>

    );
};

export default FamDocAI;
