import React, {useEffect, useState} from 'react';

const Feature = () => {
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/feature-bg1.png)`
    });

    return (
        <section id="/feature" className="feature" style={bgImg}>
            <div className="feature-decor">
                <div className="feature-circle1">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`} alt=""/>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="feature-phone">
                    </div>
                    <div className="offset-lg-4 col-lg-8">
                        <div className="row">
                            <div className="col-sm-12 mrgn-md-top">
                                <h2 className="title">
                                    features of<span> FamDoc</span>
                                </h2>
                            </div>
                            <div className="col-12 col-md-6">
                                <ul className="feature-style">
                                    <li>
                                        <div className="feature-icon">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/1.png`} alt="icon"/>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>user friendly</h3>
                                        </div>
                                        <div>
                                            <p>
                                                Famdoc was created to be very user friendly making it simple and fast to
                                                use.{" "}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`} alt="icon"/>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Highly Organized </h3>
                                        </div>
                                        <div>
                                            <p>
                                                We help you become incredibly organized Helping you be prepared for
                                                anything that may happen.{" "}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/5.png`} alt="icon"/>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Amazing Features</h3>
                                        </div>
                                        <div>
                                            <p>
                                                You can upload any documents from Audio, video files pictures and PDF.
                                                You can even create your own
                                                documents to submit as evidence.{" "}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 sm-m-top">
                                <ul className="feature-style">
                                    <li>
                                        <div className="feature-icon">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`} alt="icon"/>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Frequent Updates</h3>
                                        </div>
                                        <div>
                                            <p>
                                                Our goal at Famdoc is to continuously Improve our platform with new
                                                updates.{" "}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`} alt="icon"/>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Safe & Secure</h3>
                                        </div>
                                        <div>
                                            <p>
                                                We make sure to keep your documents safe and secure.{" "}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`} alt="icon"/>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Long Term Storage</h3>
                                        </div>
                                        <div>
                                            <p>
                                                As long as your account is open your documents/evidence will be there
                                                for you to access and use.{" "}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Feature;
