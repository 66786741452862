import React, {createContext, useState} from 'react';

const AuthContext = createContext({
    auth: {},
    setAuth: auth => null,
    decodedAuth: undefined
})

export const AuthContextProvider = ({children}) => {
    const [auth, setAuth] = useState({access: undefined, refresh: localStorage.getItem('refresh')});

    const stateValues = {
        auth,
        setAuth,
    }

    return (
        <AuthContext.Provider value={stateValues}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;