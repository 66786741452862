import React, {useEffect} from 'react';
import {Link as RichLink, useSearchParams, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Button, Card, CardBody, CardFooter, CardHeader, Flex, Heading, useToast} from "@chakra-ui/react";
import {AiOutlineArrowLeft} from "react-icons/ai";
import InputField from "../components/InputField/InputField";
import axios from "../api/axios";

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const toast = useToast({position: 'top-right'});
    const navigate = useNavigate();
    const {register, reset, handleSubmit, setError, getValues, formState: {errors}} = useForm({
        defaultValues: Object.fromEntries([...searchParams])
    });

    const formSubmit = async (data) => {
        delete data['password_confirm'];
        try {
            const resp = await axios.post('/accounts/reset-password/', data);
            toast({
                title: `Success`,
                description: 'Password updated successfully.',
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
            navigate('/login', {replace: true});
        } catch ({response}) {
            if (response.status === 400) {
                const {data} = response;
                setError('password', {
                    type: 'server',
                    message: data?.detail ? data.detail: Object.values(data).join('\n')
                })
            }
        }
    }


    return (
        <>
            <Button
                leftIcon={<AiOutlineArrowLeft/>}
                variant={'ghost'}
                colorScheme={'teal'}
                _hover={{bg: 'transparent', color: 'fam.800'}}
                as={RichLink}
                to={'/login'}
            >
                Back To Login
            </Button>
            <Flex
                justifyContent={'center'}
                alignContent={'center'}
                mt={'100px'}
            >
                <Card as={'form'} boxShadow={'dark-lg'} onSubmit={handleSubmit(formSubmit)}>
                    <CardHeader>
                        <Heading letterSpacing={0.2} fontSize={20}>Send Reset Link</Heading>
                    </CardHeader>
                    <CardBody display={'flex'} flexDirection={'column'} alignItems={'center'} rowGap={2}>
                        <InputField
                            name={"password"}
                            type={"password"}
                            placeholder={"password"}
                            register={register}
                            errors={errors}
                            options={{required: true, maxLength: 50}}
                        />
                        <InputField
                            name={"password_confirm"}
                            type={"password"}
                            placeholder={"password confirm"}
                            register={register}
                            errors={errors}
                            options={{
                                required: true,
                                maxLength: 50,
                                validate: {
                                    matchesPreviousPassword: value => {
                                        const {password} = getValues();
                                        return password === value || "Password mismatch!"
                                    }
                                }
                            }}
                        />
                    </CardBody>
                    <CardFooter justifyContent={'center'}>
                        <Button type={'submit'} colorScheme={'teal'}>
                            Submit
                        </Button>
                    </CardFooter>
                </Card>
            </Flex>
        </>
    );
};

export default ResetPassword;
