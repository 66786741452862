import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider} from "@chakra-ui/react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {extendTheme} from "@chakra-ui/theme-utils";
import {HelmetProvider} from "react-helmet-async";

const theme = extendTheme({
    colors: {
        fam: {
            50: '#494b4b',
            100: '#F1FFFE',
            400: '#DDF8F6',
            800: '#4DC3BD',
            900: '#3DB866'
        },
    },
})

const helmetContext = {}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>

        <ChakraProvider theme={theme}>
            <HelmetProvider context={helmetContext}>
                <BrowserRouter>
                    <Routes>
                        <Route path={'*'} element={<App/>}/>
                    </Routes>
                </BrowserRouter>
            </HelmetProvider>
        </ChakraProvider>

    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
