import React from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader, Flex, Heading, useToast} from "@chakra-ui/react";
import InputField from "../components/InputField/InputField";
import {useForm} from "react-hook-form";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {Link as RichLink} from "react-router-dom";
import axios from "../api/axios";

const LostPassword = () => {
    const {register, handleSubmit, setError, formState: {errors}} = useForm();
    const toast = useToast({position: 'top-right'});
    const formSubmit = async (data) => {
        try {
            const resp = await axios.post('/accounts/send-reset-password-link/', data)
            toast({
                title: `Success`,
                description: 'Password reset link sent to your email successfully.',
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
        } catch ({response}) {
            if (response.status === 400) {
                const {data} = response;
                setError('login', {
                    type: 'server',
                    message: data?.detail ? data.detail: data?.login
                });
            }

        }
    }
    return (
        <>
            <Button
                leftIcon={<AiOutlineArrowLeft/>}
                variant={'ghost'}
                colorScheme={'teal'}
                _hover={{bg: 'transparent', color: 'fam.800'}}
                as={RichLink}
                to={'/login'}
            >
                Back To Login
            </Button>
            <Flex
                justifyContent={'center'}
                alignContent={'center'}
                mt={'100px'}
            >
                <Card as={'form'} boxShadow={'dark-lg'} onSubmit={handleSubmit(formSubmit)}>
                    <CardHeader>
                        <Heading letterSpacing={0.2} fontSize={20}>Send Reset Link</Heading>
                    </CardHeader>
                    <CardBody>
                        <InputField
                            register={register}
                            name={'login'}
                            type={'email'}
                            errors={errors}
                            placeholder={'Enter Registered Email'}
                            options={{required: true, maxLength: 100}}
                        />
                    </CardBody>
                    <CardFooter justifyContent={'center'}>
                        <Button type={'submit'} colorScheme={'teal'}>
                            Submit
                        </Button>
                    </CardFooter>
                </Card>
            </Flex>
        </>
    );
};

export default LostPassword;
