import React, {useEffect, useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Spacer,
    useToast
} from '@chakra-ui/react';
import {useForm} from "react-hook-form";
import useAxiosPrivate from "../../hooks/use.axios-private";
import useDocument from "../../hooks/use.document";
import FormFields from "./FormFields";
import FileUploader from "./FileUploader";

const DocModal = ({category, title, isOpen, onClose, isLoading}) => {
    const toast = useToast({position: 'top-right'});
    const {document, setDocument, files} = useDocument();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(isLoading)
    const [uploadFile, setUploadFile] = useState(false);
    const {register, handleSubmit, setValue, reset, resetField, formState: {errors}} = useForm();

    useEffect(() => {
        setValue('name', document?.name);
        setValue('notes', document?.notes);
        if (document?.id) setValue('id', document?.id);
    }, [document?.id]);


    const onSubmit = async (data) => {
        try {
            setLoading(true);
            data['category'] = category
            let resp = null
            if (document?.id) {
                resp = await axiosPrivate.put(`/api/v1/famdoc/documents/${document?.id}`, data);
            } else {
                resp = await axiosPrivate.post(`/api/v1/famdoc/documents/`, data);
            }
            setDocument(resp?.data);
            setUploadFile(true);
            toast({
                title: `${resp?.data.name}`,
                description: "Changes Saved Successfully.",
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const toggleView = () => {
        setUploadFile(!uploadFile);
    }

    const serverUploadFile = async data => {
        setLoading(true)
        try {
            const resp = await axiosPrivate.post('/api/v1/famdoc/files/', data)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const handleFileUpload = data => {
        files.forEach(item => {
            const formData = new FormData();
            formData.append('filepath', item, item.name);
            formData.append('document', data?.id);
            serverUploadFile(formData).then();
        })
        toast({
            title: `Uploading`,
            description: "Files Uploaded Successfully.",
            status: 'success',
            duration: 4000,
            isClosable: true,
        })
    }

    const handleOnClose = () => {
        reset();
        resetField('name')
        resetField('notes')
        onClose();
        setDocument({})
        setUploadFile(false);
        setLoading(false);
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleOnClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton/>
                <form onSubmit={handleSubmit(uploadFile ? handleFileUpload : onSubmit)}>
                    <ModalBody pb={6}>
                        {
                            (document?.id && uploadFile) ? (
                                <FileUploader/>
                            ) : (
                                <FormFields
                                    errors={errors}
                                    register={register}
                                />
                            )
                        }
                    </ModalBody>

                    <ModalFooter justifyContent={'flex-start'}>
                        <Button
                            size={'sm'}
                            disabled={!document?.id}
                            onClick={toggleView}
                            colorScheme='orange'
                        >
                            {uploadFile ? 'Edit Note' : 'Upload Files'}
                        </Button>
                        <Spacer/>
                        <Button
                            type={'submit'}
                            size={'sm'}
                            mr={4}
                            isLoading={loading}
                            loadingText='Submitting'
                            colorScheme='teal'
                        >
                            Submit
                        </Button>
                        <Button size={'sm'} onClick={onClose}>Close</Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default DocModal;
