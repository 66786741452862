import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Flex,
    Heading,
    Text,
    Avatar,
    Badge,
    Icon
} from '@chakra-ui/react';
import {icons} from "../../api/icon-mapps";
import {Link} from "react-router-dom";

const Category = ({category}) => {
    const {id, key, name, total_docs} = category;
    const icon = icons[key];
    return (
        <Card maxW='md' boxShadow={'md'} as={Link} to={'/docs/' + key}>
            <CardHeader>
                <Flex spacing='4'>
                    <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                        <Avatar bg='fam.100' color={'fam.800'} icon={<Icon as={icon} />} fontSize='1.5rem' />
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody>
                <Heading title={name} noOfLines={1} as={'h3'} size='sm'>{name}</Heading>
            </CardBody>

            <CardFooter
                py={'2'}
                justify='space-between'
                alignItems={'center'}
                bg={'fam.400'}
                flexWrap='wrap'
                sx={{
                    '& > button': {
                        minW: '136px',
                    },
                }}
            >
                <Text fontWeight={'bold'}>Total Items</Text>
                <Badge bg={'fam.800'} color={'white'} px={4} py={2} fontSize='1em' rounded={'3xl'}>
                    {total_docs}
                </Badge>
            </CardFooter>
        </Card>
    );
};

export default Category;
