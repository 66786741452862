import React from 'react';
import {
    Box,
    Button,
    Container, Image,
    Link,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
} from '@chakra-ui/react';
import {Link as RichLink} from 'react-router-dom';


const SocialButton = ({children, label, href}) => {
    return (
        <Button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </Button>
    );
};

const Footer = () => {
    return (
        <Box
            h={'60px'}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container
                as={Stack}
                maxW={'6xl'}
                spacing={4}
                justify={'center'}
                align={'center'}>
                <a target={'_blank'}
                             href={"https://www.lawdepot.com/contracts/groups/family/?pid=pg-JWTJV2H8A1-Family_728x90Light.jpg"}>
                    <Image objectFit={'contain'} src={process.env.PUBLIC_URL + "/assets/images/banners/lawdepot728x90.jpg"}/>
                </a>
            </Container>

            <Box
                borderTopWidth={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.700')}>
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    direction={{base: 'column', md: 'row'}}
                    spacing={4}
                    justify={{base: 'center', md: 'space-between'}}
                    align={{base: 'center', md: 'center'}}>
                    <Text>© {new Date().getFullYear()} FamDoc. All rights reserved</Text>
                    <Stack direction={'row'} spacing={6}>
                        <Link _hover={{color: 'black'}} to={'/privacy-policy'} as={RichLink}>
                            Privacy Policy
                        </Link>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
};

export default Footer;
