import React, {useEffect} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import useAuth from "../../hooks/use.auth";

const Logout = () => {
    const {setAuth} = useAuth();
    useEffect(() => {
        localStorage.clear()
        setAuth({access: '', refresh: ''})
    }, []);

    return (
        <Navigate to={'/login'} state={{from: '/dashboard'}} replace />
    );
};

export default Logout;
