import React, {useState} from 'react';
import {
    Box,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Input, useToast
} from '@chakra-ui/react';
import {Link as RichLink} from "react-router-dom";
import {useForm} from "react-hook-form";
import useAxiosPrivate from "../hooks/use.axios-private";

const ChangePassword = () => {
    const [showPwd, setShowPwd] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const toast = useToast({position: 'top-right'});
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, getValues, formState: {errors}} = useForm();

    const onSubmit = async data => {
        setLoading(true);
        try {
            const resp = await axiosPrivate.post('/accounts/change-password/', data);
            toast({
                title: `SUCCESS`,
                description: "Password Changed Successfully.",
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
        } catch (error) {
            const response = await error?.response;
            if (response?.status === 400) {
                const data = response.data;
                Object.keys(data).map(key => {
                    toast({
                        title: `FAILED`,
                        description: data[key].join('\n'),
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                    })
                })
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Flex mt={'50px'} mb={5} alignItems={'center'} justifyContent={'center'}>
            <Card width={'md'} boxShadow={'lg'}>
                <CardHeader fontSize={'20px'} fontWeight={'700'}>Change Password</CardHeader>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CardBody>
                        <Flex rowGap={'10px'} flexDirection={'column'}>
                            <FormControl isInvalid={errors.old_password}>
                                <Input
                                    type={"password"}
                                    className={'form-control'}
                                    placeholder={'Old Password'}
                                    {...register('old_password', {required: true})}
                                />
                                <FormErrorMessage>
                                    {
                                        errors?.old_password?.type === 'required' ? 'This field is required' : errors?.old_password?.message
                                    }
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.password}>
                                <Input
                                    type={"password"}
                                    className={'form-control'}
                                    placeholder={'Password'}
                                    {...register('password', {required: true})}
                                />
                                <FormErrorMessage>
                                    {
                                        errors.password?.type === 'required' ? 'This field is required' : null
                                    }
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.password_confirm}>
                                <Input
                                    type={"password"}
                                    className={'form-control'}
                                    placeholder={'Password Confirm'}
                                    {...register(
                                        'password_confirm',
                                        {
                                            required: true,
                                            validate: {
                                                matchesPreviousPassword: value => {
                                                    const {password} = getValues();
                                                    return password === value || "Password mismatch!"
                                                }
                                            }
                                        }
                                    )}
                                />
                                <FormErrorMessage>
                                    {
                                        errors.password?.type === 'required' ? 'This field is required' : errors?.password_confirm?.message
                                    }
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                    </CardBody>
                    <CardFooter>
                        <FormControl
                            type={'submit'}
                            _hover={{bg: 'teal.700'}}
                            color={'white'} bg={'teal'}
                            as={Button}
                            isLoading={loading}
                            loadingText='Submitting'
                        >
                            Submit
                        </FormControl>
                    </CardFooter>
                </form>
            </Card>
        </Flex>
    );
};

export default ChangePassword;
