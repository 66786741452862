import moment from "moment";
import jwtDecode from "jwt-decode";

export const BASE_URL = 'https://api.famdoc.cloud'
// export const BASE_URL = 'http://127.0.0.1:8000'
export const WS_URL = 'wss://api.famdoc.cloud/ws/notifications/'
// export const WS_URL = 'ws://127.0.0.1:8001/ws/notifications/'
export const getDate = (dateStr) => {
    return moment(dateStr).format('DD-MM-YYYY hh:mm A')
}

export const currentUser = () => {
    const refresh = localStorage.getItem('refresh')
    return refresh ? jwtDecode(refresh) : {};
}
