import React, {useState} from 'react';
import {Card, CardHeader, CardBody, CardFooter, Button, Link, useToast, Heading} from '@chakra-ui/react';
import useAxiosPrivate from "../../hooks/use.axios-private";
import * as Swal from "sweetalert2";

const LawyerCard = ({id, is_connected, location, name, phone}) => {
    const axiosPrivate = useAxiosPrivate();
    const toast = useToast({position: 'top-right'})
    const [isConnected, setIsConnected] = useState(is_connected);

    const connectLawyer = async () => {
        Swal.fire({
            title: `Do you want to connect to ${name}?`,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const resp = await axiosPrivate.post('/api/v1/famdoc/user-lawyers/', {lawyer: id});
                    toast({
                        title: `SUCCESS`,
                        description: `You are connected to ${name}`,
                        status: 'success',
                        duration: 4000,
                        isClosable: true,
                    })
                    setIsConnected(true);
                } catch (e) {

                }
            }
        })
    }

    return (
        <Card h={'230px'}>
            <CardHeader bg={'fam.100'}>
                <Heading as={'h3'} title={name} noOfLines={1} color={'fam.800'} fontSize={'20'} fontWeight={'700'}>{name}</Heading>
            </CardHeader>
            <CardBody>
                {location?.street_address + " " + location?.city + " " + location?.province + "(" + location?.postal_code + ")"}
            </CardBody>
            <CardFooter p={2} bg={'fam.100'} justifyContent={'flex-end'}>
                {
                    isConnected ? <Link p={1} as={'a'} color={'fam.800'} _hover={{color: 'fam.800'}}
                                        href={"tel:+" + phone}>Call: {phone}</Link> :
                        <Button onClick={connectLawyer} size={'sm'} colorScheme={'teal'}>Connect</Button>
                }
            </CardFooter>
        </Card>
    );
};

export default LawyerCard;
