import React, {createContext, useState} from "react";

const DocumentContext = createContext({
    document: {},
    setDocument: doc => null,
    files: [],
    setFiles: files => null
})

export const DocumentContextProvider = ({children}) => {
    const [document, setDocument] = useState({});
    const [files, setFiles] = useState([]);

    const stateValues = {
        document,
        setDocument,
        files,
        setFiles
    }

    return <DocumentContext.Provider value={stateValues}>{children}</DocumentContext.Provider>
}

export default DocumentContext