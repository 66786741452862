import React from 'react';
import {
    IconButton,
    Button,
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Image,
    Spacer,
    useDisclosure, HStack,
} from '@chakra-ui/react';
import {FiMenu, FiHome} from 'react-icons/fi';

import {System, Help} from '@icon-park/react';
import {Link as RichLink, useLocation} from 'react-router-dom';
import useCategories from "../../hooks/use.categories";
import {icons} from "../../api/icon-mapps";
import UserMenu from "./UserMenu";
import Footer from "./Footer";
import Notifications from "../Notifications/Notifications";


const SidebarContent = ({onClose, ...rest}) => {
    const {categories} = useCategories();
    return (
        <Flex
            bg={useColorModeValue('fam.100', 'fam.900')}
            flexFlow={'column'}
            fontSize={"sm"}
            borderRight="1px"
            borderRightColor={useColorModeValue('fam.100', 'fam.900')}
            shrink={0}
            w={{base: 'full', md: '52'}}
            h="full"
            {...rest}
        >
            <Flex h="80px" alignItems="center" m="8" justifyContent="center">
                <Link as={RichLink} to={'/'}>
                    <Image boxSize={'80px'} objectFit={'contain'}
                           src={process.env.PUBLIC_URL + "/assets/images/logo.png"}/>
                </Link>
                <CloseButton
                    display={{base: 'flex', md: 'none'}}
                    onClick={onClose}
                    style={{position: 'absolute', top: '10px', right: '10px'}}
                />
            </Flex>
            <NavItem icon={System} path={'/dashboard'}>Dashboard</NavItem>
            {
                categories?.map((category) => (
                    <NavItem key={category.id} icon={icons[category.key] ? icons[category.key] : Help}
                             path={'docs/' + category.key}>
                        {category.name}
                    </NavItem>
                ))
            }
            <a target={'_blank'}
               href={"https://www.lawdepot.com/contracts/groups/family/?pid=pg-JWTJV2H8A1-Family_728x90Light.jpg"}>
                <Image m={'2rem auto 0'} maxWidth={'300px'} width={'100%'} objectFit={'contain'}
                       src={process.env.PUBLIC_URL + "/assets/images/banners/lawdepot300x250.jpg"}/>
            </a>
        </Flex>
    );
};


const NavItem = ({icon, path, children, ...rest}) => {
    const {pathname} = useLocation();
    return (
        <Link
            as={RichLink}
            to={path}
            style={{textDecoration: 'none'}}
            _focus={{boxShadow: 'none', color: 'black'}}
        >
            <Flex
                align="center"
                p="1"
                mx="0"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                fontWeight={'400'}
                color={pathname.endsWith(path) ? 'fam.800' : 'fam.50'}
                bg={pathname.endsWith(path) ? 'fam.400' : 'fam.200'}

                _hover={{
                    bg: 'fam.400',
                    color: 'fam.800',
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        color={'fam.800'}
                        _groupHover={{
                            color: 'fam.800',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};
const MobileNav = ({onOpen, ...rest}) => {
    return (
        <Flex
            ml={{base: 0, md: 0}}
            px={{base: 4, md: 24}}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="space-between"
            {...rest}
        >
            <Link as={RichLink} to={'/'}>
                <Image boxSize={'50px'} objectFit={'contain'} src={process.env.PUBLIC_URL + "/assets/images/logo.png"}/>
            </Link>
            <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu/>}
            />
        </Flex>
    );
};

const Sidebar = ({children}) => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
        <Flex
            width={'100%'}
            direction={{base: 'column', md: 'row'}}
            bg={useColorModeValue('white', 'gray.900')}
        >
            <SidebarContent
                onClose={() => onClose}
                display={{base: 'none', md: 'block'}}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent bg={useColorModeValue('fam.100', 'fam.900')}>
                    <SidebarContent onClose={onClose}/>
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav display={{base: 'flex', md: 'none'}} onOpen={onOpen}/>

            <Box width={'100%'}>
                <Flex
                    columnGap={2}
                    alignItems={'center'}
                    h={'60px'}
                    px={4}
                >
                    <Button
                        size={'xs'}
                        _hover={{color: 'fam.800'}}
                        leftIcon={<Icon as={FiHome}/>}
                        as={RichLink}
                        to={'/'}
                        variant={'link'}
                    >
                        Home
                    </Button>
                    <Spacer/>
                    <Notifications/>
                    <UserMenu/>
                </Flex>
                <Box width={'100%'} p={4}>
                    {children}
                </Box>
                <Footer/>
            </Box>


        </Flex>
    );
}

export default Sidebar;
