import React from "react";
import {Flex, Text, Heading, Image} from '@chakra-ui/react'

const DocRenderer = ({document, docs}) => {
    return (
        <Flex id={'save-pdf'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} rowGap={'10px'}>
            <Heading textAlign={'left'} as={'h3'}>{document?.name}</Heading>
            <Text>{document?.notes}</Text>
            {
                docs.map(item => {
                    if (item.name.toLowerCase().endsWith('png') || item.name.toLowerCase().endsWith('jpg') || item.name.toLowerCase().endsWith('jpeg')) {
                        return (
                            <Image
                                width={'100%'}
                                height={'100%'}
                                objectFit={'contain'}
                                key={item.id}
                                src={item.filepath}
                                alt={item.name}
                            />
                        )
                    }
                })
            }
        </Flex>
    );
};

export default DocRenderer;
