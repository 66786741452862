import React from 'react';
import useAuth from "../hooks/use.auth";
import {Navigate, useLocation, Outlet} from "react-router-dom";
import {CategoriesProvider} from "../context/categories.context"
import Sidebar from "./Navbar/Sidebar";
import {DocumentContextProvider} from "../context/document.context";

const AuthRequired = () => {
    const {auth} = useAuth();
    const location = useLocation();
    return (
        auth?.refresh ? (
            <CategoriesProvider>
                <Sidebar>
                    <DocumentContextProvider>
                        <Outlet/>
                    </DocumentContextProvider>
                </Sidebar>
            </CategoriesProvider>
        ) : <Navigate to={'/login'} state={{from: location}} replace/>
    );
};

export default AuthRequired;