import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    FormControl,
    FormErrorMessage,
    Heading,
    Select,
    Textarea, useToast
} from "@chakra-ui/react";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {Link as RichLink, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import InputField from "../components/InputField/InputField";
import useAxiosPrivate from "../hooks/use.axios-private";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddBlog = () => {
    const {register, handleSubmit, getValues, setError, setValue, formState: {errors}} = useForm({
        defaultValues: {content: ''},

    });
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const toast = useToast({position: "top-right"})
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const controller = new AbortController();

    useEffect(() => {
        const fetchCategories = async () => {
            setLoading(true)
            try {
                const {data} = await axiosPrivate.get('/api/v1/blogs/categories/', {signal: controller.signal})
                setCategories(data.results);
            } catch ({response}) {
            } finally {
                setLoading(false)
            }
        }
        fetchCategories().then()
        return () => {
            if (loading) controller.abort();
        };
    }, []);

    const onQuilChange = value => {
        setValue('content', value);
    }


    const onSubmit = async data => {
        if (!getValues().content) {
            setError('content', {type: 'required', message: 'This field is required'})
            return
        }
        setLoading(true)
        const formData = new FormData();
        const {image} = data;
        formData.append('image', image[0], image[0].name);
        delete data['image'];
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        })
        try {
            const response = await axiosPrivate.post('/api/v1/blogs/my-blogs/', formData, {signal: controller.signal});
            toast({
                title: `SUCCESS`,
                description: `${response.data.title} added successfully.`,
                status: 'success',
                duration: 4000,
                isClosable: true,
            });
            navigate('/my-blogs', {replace: true})
        } catch ({response}) {
            console.log(response);
        } finally {
            setLoading(false)
        }
    }
    return (
        <Box>
            <Button
                leftIcon={<AiOutlineArrowLeft/>}
                variant={'ghost'}
                colorScheme={'teal'}
                _hover={{bg: 'transparent', color: 'fam.800'}}
                as={RichLink}
                to={'/my-blogs'}
            >
                Back To My Blogs
            </Button>
            <Card
                mt={5}
                as={'form'}
                onSubmit={handleSubmit(onSubmit)}
                boxShadow={'md'}
            >
                <CardHeader>
                    <Heading letterSpacing={0.2} fontSize={20} fontWeight={700}>Add Blog</Heading>
                </CardHeader>
                <CardBody display={'flex'} flexDirection={'column'} rowGap={3}>
                    <InputField
                        type={'text'}
                        placeholder={'Enter Title'}
                        name={'title'}
                        register={register}
                        errors={errors}
                        options={{required: true, maxLength: 250}}
                    />
                    <FormControl isInvalid={errors?.category}>
                        <Select
                            placeholder={'Select Category'}
                            {...register('category', {required: true})}
                        >
                            {
                                categories.map(item => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                    }
                                )
                            }
                        </Select>
                        <FormErrorMessage>
                            {
                                errors?.category?.type === 'required' ?
                                    'This field is required' :
                                    null
                            }
                        </FormErrorMessage>
                    </FormControl>
                    <InputField
                        type={'text'}
                        placeholder={'Enter Short Description'}
                        name={'description'}
                        register={register}
                        errors={errors}
                        options={{required: true, maxLength: 250}}
                    />
                    <FormControl isInvalid={errors?.content}>
                        <ReactQuill value={getValues()?.content} onChange={onQuilChange} theme="snow"/>
                        <FormErrorMessage>
                            {
                                errors.content?.type === 'required' ?
                                    'This field is required' :
                                    errors.content?.message
                            }
                        </FormErrorMessage>
                    </FormControl>
                    <InputField
                        type={'file'}
                        variant={'flushed'}
                        className={null}
                        placeholder={'Select Blog Image'}
                        name={'image'}
                        register={register}
                        errors={errors}
                        options={{required: true}}
                    />
                </CardBody>
                <CardFooter justifyContent={'flex-end'}>
                    <Button
                        isLoading={loading}
                        loadingText={'Submitting...'}
                        colorScheme={'teal'}
                        type={'submit'}
                    >
                        Submit
                    </Button>
                </CardFooter>
            </Card>
        </Box>
    );
};

export default AddBlog;
