import axios from "../api/axios";
import useAuth from "./use.auth";

const useRefreshToken = () => {
    const {setAuth} = useAuth();
    return async () => {
        try {
            const response = await axios.post('/accounts/token/refresh/', {refresh: localStorage.getItem('refresh')})
            const access = response.data.access;
            setAuth(prev => {
                return {...prev, access: access}
            })
            return access
        } catch (e) {
            return ''
        }

    }
};

export default useRefreshToken;