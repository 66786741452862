import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import './App.scss';
import NotFound from "./components/NotFound/NotFound";
import Layout from "./components/Layout/Layout";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import Register from "./Pages/Register";
import AuthRequired from "./components/Auth.Required";
import {AuthContextProvider} from "./context/auth.context";
import Logout from "./components/Logout/Logout";
import Dashboard from "./Pages/Dashboard";
import DocListing from "./Pages/DocListing";
import DocDetails from "./Pages/DocDetails";
import ChangePassword from "./Pages/ChangePassword";
import Lawyers from "./Pages/Lawyers";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import BlogListing from "./Pages/BlogListing";
import BlogDetails from "./Pages/BlogDetails";
import LostPassword from "./Pages/LostPassword";
import ResetPassword from "./Pages/ResetPassword";
import MyBlogs from "./Pages/MyBlogs";
import AddBlog from "./Pages/AddBlog";
import FamDocAI from "./Pages/FamdocAI";
import DocPreviewer from "./Pages/DocPreviewer";

function App() {
    useEffect(() => {
        let timer = setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        return () => {
            clearTimeout(timer)
        }
    }, []);
    return (
        <AuthContextProvider>
            <Routes>
                <Route element={<Layout/>}>
                    {/* public routes   */}
                    <Route path={'/'} element={<Home/>}/>
                    <Route exact path={'/about'} element={<Home/>}/>
                    <Route exact path={'/feature'} element={<Home/>}/>
                    <Route exact path={'/screenshot'} element={<Home/>}/>
                    <Route exact path={'/contact'} element={<Home/>}/>
                    <Route exact path={'/team'} element={<Home/>}/>
                    <Route exact path={'/testimonial'} element={<Home/>}/>
                    <Route exact path={'/blogs'} element={<BlogListing/>}/>
                    <Route exact path={'/blogs/:blogSlug'} element={<BlogDetails/>}/>
                    <Route exact path={'/privacy-policy'} element={<PrivacyPolicy/>}/>
                    <Route exact path={'/forget-password'} element={<LostPassword/>}/>
                    <Route exact path={'/reset-password/'} element={<ResetPassword/>}/>
                    <Route exact path={'/famdocai/'} element={<FamDocAI/>}/>
                    <Route exact path={'/login'} element={<Login/>}/>
                    <Route exact path={'/register'} element={<Register/>}/>
                    <Route exact path={'/logout'} element={<Logout/>}/>

                    {/* protected/user routes   */}
                    <Route element={<AuthRequired/>}>
                        <Route exact path={'/dashboard'} element={<Dashboard/>}/>
                        <Route exact path={'/change-password'} element={<ChangePassword/>}/>
                        <Route exact path={'/my-blogs'} element={<MyBlogs/>}/>
                        <Route exact path={'/my-blogs/add'} element={<AddBlog/>}/>
                        <Route exact path={'/docs/lawyers'} element={<Lawyers/>}/>
                        <Route exact path={'/docs/:categoryId'} element={<DocListing/>}/>
                        <Route exact path={'/docs/:categoryId/details/:documentId'} element={<DocDetails/>}/>
                        <Route exact path={'/docs/:categoryId/preview/:documentId'} element={<DocPreviewer/>}/>
                    </Route>

                    {/* invalid route not found   */}
                    <Route path={'*'} element={<NotFound/>}/>
                </Route>
            </Routes>
        </AuthContextProvider>
    );
}

export default App;
