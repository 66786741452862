import React from 'react';
import {FormControl, FormErrorMessage, FormLabel, Input, ModalBody} from "@chakra-ui/react";

const FormFields = ({errors, register}) => {
    return (
        <>
            <FormControl isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                    placeholder='Enter Name'
                    {...register('name', {required: true, maxLength: 100})}
                />
                <FormErrorMessage>
                    {
                        errors.name?.type === 'required' ?
                            'This field is required' :
                            errors.name?.type === 'maxLength' ?
                                'This field should be less than or equal to 100 characters' :
                                null
                    }
                </FormErrorMessage>
            </FormControl>

            <FormControl mt={4}>
                <FormLabel>Notes</FormLabel>
                <Input
                    placeholder='Enter Notes'
                    {...register('notes', {required: true, maxLength: 250})}
                />
                <FormErrorMessage>
                    {
                        errors.name?.type === 'maxLength' ?
                            'This field should be less than or equal to 250 characters' :
                            null
                    }
                </FormErrorMessage>
            </FormControl>
        </>
    );
};

export default FormFields;
