import React from 'react';

import {Card, CardBody, Stack, Heading, Text, Grid} from '@chakra-ui/react';

import useCategories from "../hooks/use.categories";
import Category from "../components/Category/Category";

const Dashboard = () => {
    const {categories} = useCategories();
    return (
        <>
            <Card
                bgGradient="linear(to-l, fam.900, fam.800)"
                style={{padding: '16px'}}
                direction={{base: 'column', sm: 'row'}}
                my={'32px'}
            >
                <iframe
                    width="284"
                    height="163"
                    src="https://www.youtube.com/embed/vpkAVnKbRvA"
                    title="FamDoc   Google Chrome 2022 05 05 19 25 56"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />

                <Stack>
                    <CardBody>
                        <Heading color={'white'} as={'h3'} size='md'>How To Use Our Dashboard.</Heading>

                        <Text color={'white'} py='2'>
                            Thank You For Joining FamDoc. This Video Is A Quick Tutorial Of How To Use Your Dashboard.
                            We Have Many New Features Coming Soon.
                        </Text>
                    </CardBody>
                </Stack>
            </Card>
            <div className="row">
                {
                    categories.map(
                        item => {
                            return (
                                <div key={item.id} className="col-md-3 col-sm-12 mb-lg-5">
                                    <Category category={item}/>
                                </div>
                            )
                        }
                    )
                }
            </div>
        </>
    );
};

export default Dashboard;
