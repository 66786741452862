import React, {useEffect, useState} from 'react';
import {Menu, MenuButton, IconButton, MenuList, MenuItem, Flex, Heading, Text, useToast} from '@chakra-ui/react';
import {FiBell} from "react-icons/fi";
import useWebSocket from "react-use-websocket";
import {getDate, WS_URL} from "../../api/config";
import useAxiosPrivate from "../../hooks/use.axios-private";

const Notifications = () => {
    const {lastMessage} = useWebSocket(WS_URL);
    const toast = useToast({position: 'top-right'});
    const axiosPrivate = useAxiosPrivate();
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        if (lastMessage !== null) {
            const noti = JSON.parse(JSON.parse(lastMessage.data).message)
            toast({
                title: noti?.title,
                description: noti?.message,
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
            setNotifications([noti, ...notifications]);
        }
    }, [lastMessage, setNotifications]);

    useEffect(() => {
        const controller = new AbortController();
        const fetchNotifications = async () => {
            try {
                const resp = await axiosPrivate.get('/api/v1/notifications/?limit=5', {signal: controller.signal});
                setNotifications(resp.data.results);
            } catch (e) {

            }
        }
        fetchNotifications().then();
        return () => {
            controller.abort();
        };
    }, [axiosPrivate]);



    return (
        <Menu size={'300px'}>
            <MenuButton
                as={IconButton}
                size="xs"
                variant="ghost"
                aria-label="open menu"
                icon={<FiBell/>}
            />
            <MenuList>
                {
                    notifications?.map((item, index) => {
                        return (
                            <MenuItem key={index} _hover={{bg: "fam.100"}}>
                                <Flex
                                    justifyContent={'flex-start'}
                                    flexDirection={'column'}
                                    maxW={'200px'}
                                >
                                    <Heading oOfLines={1} color={'fam.800'} as={'h4'} fontSize={16}>
                                        {item.title}
                                    </Heading>
                                    <Text>{item.message}</Text>
                                    <Text noOfLines={1} fontSize={10}>{getDate(item.created_at)}</Text>
                                </Flex>
                            </MenuItem>
                        )
                    })
                }
            </MenuList>
        </Menu>
    );
};

export default Notifications;
