import React, {useEffect, useState} from 'react';
import {
    Badge,
    Box,
    Button,
    Flex,
    IconButton, Image,
    Spacer,
    Spinner, StackDivider, Tag,
    Text,
    useDisclosure, useToast, VStack
} from "@chakra-ui/react";
import {AiOutlinePlus, AiOutlineDelete, AiOutlineComment} from "react-icons/ai";
import {Link as RichLink} from 'react-router-dom';
import useAxiosPrivate from "../hooks/use.axios-private";
import {getDate} from "../api/config";
import ConfirmDialog from "../components/ConfirmDialog/ConfirmDialog";

const MyBlogs = () => {
    const axiosPrivate = useAxiosPrivate();
    const toast = useToast({position: 'top-right'});
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [currentBlog, setCurrentBlog] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const controller = new AbortController();

    const fetchBlogs = async () => {
        setLoading(true);
        try {
            const {data} = await axiosPrivate.get('/api/v1/blogs/my-blogs/', {signal: controller.signal});
            setBlogs(data.results);
        } catch ({response}) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchBlogs().then()
        return () => {
            if (loading) controller.abort();
        };
    }, []);

    const onDialogOpen = blog => {
        setCurrentBlog(blog);
        onOpen();
    }

    const onDialogClose = () => {
        setCurrentBlog(undefined);
        onClose();
    }

    const onDialogConfirm = blog => {
        deleteBlog(blog).then()
        setCurrentBlog(undefined);
        onClose();
    }

    const deleteBlog = async (blog) => {
        try {
            const resp = await axiosPrivate.delete(`/api/v1/blogs/my-blogs/${blog.id}`);
            toast({
                title: `SUCCESS`,
                description: `${blog.title} deleted successfully.`,
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
            await fetchBlogs();
        } catch ({response}) {
            if (response.status === 400) {
                toast({
                    title: `FAILURE`,
                    description: response.data.detail,
                    status: 'failure',
                    duration: 4000,
                    isClosable: true,
                })
            }
        }
    }

    return (
        <>
            <Flex boxShadow={'md'} bg={'fam.100'} p={4} borderRadius={'md'} alignItems={'bold'}>
                <Text color={'fam.800'} fontWeight={'700'} fontSize={'20'}>
                    My Blogs
                </Text>
                <Spacer/>
                <Button
                    size={'sm'}
                    leftIcon={<AiOutlinePlus/>}
                    colorScheme={'teal'}
                    as={RichLink}
                    to={'/my-blogs/add'}
                >
                    Add New
                </Button>
            </Flex>
            <Box position={'relative'} minH={'350px'}>
                {
                    loading && <Spinner
                        position={'absolute'}
                        top={'50%'}
                        left={'50%'}
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='fam.100'
                        color='fam.800'
                        size='xl'
                    />
                }
                {
                    (!loading && blogs.length === 0) && (
                        <Flex mt={30} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                            <img
                                width="150"
                                src={`${process.env.PUBLIC_URL}/assets/images/not-found.gif`}
                                alt="no-data"
                            />
                            <Text fontSize={'20'} fontWeight={'700'} color={'fam.800'}>No Blogs Yet!</Text>
                        </Flex>
                    )
                }
                <ConfirmDialog
                    title={'Delete Blog'}
                    isOpen={isOpen}
                    onClose={onDialogClose}
                    handleOk={onDialogConfirm}
                    item={currentBlog}
                />
                <VStack
                    mt={4}
                    boxShadow={'md'}
                    divider={<StackDivider borderColor='gray.200'/>}
                    spacing={4}
                    align='stretch'
                >
                    {
                        blogs.map(item => {
                            return (
                                <Flex
                                    rounded={'md'}
                                    p={2}
                                    columnGap={2}
                                    key={item.id}
                                    alignItems={'center'}
                                >
                                    <Image borderRadius={'100%'} boxSize={'80px'} objectFit={'cover'} src={item.image}/>
                                    <Box width={'18%'}>
                                        <Text noOfLines={1} fontSize={14}>{item.title}</Text>
                                        <Text noOfLines={1} fontSize={10}
                                              color={'fam.800'}>{getDate(item.created_at)}</Text>
                                    </Box>
                                    <Box width={'18%'}>
                                        <Text noOfLines={1} fontSize={14}>
                                            {item.category_name}
                                        </Text>
                                        <AiOutlineComment color={'green'}/>
                                        <Badge colorScheme='green'>{item.total_comments}</Badge>
                                    </Box>
                                    <Box flex={1}>
                                        <Text noOfLines={1} fontSize={14}>{item.description}</Text>
                                        <Tag colorScheme={item.approved ? 'green': 'orange'}>{item.approved ? 'Approved': 'Pending'}</Tag>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            colorScheme={'red'}
                                            icon={<AiOutlineDelete/>}
                                            size={'sm'}
                                            variant={'outline'}
                                            aria-label={'Delete Blog'}
                                            onClick={event => onDialogOpen(item)}
                                        />
                                    </Box>
                                </Flex>
                            )
                        })
                    }
                </VStack>
            </Box>
        </>
    );
};

export default MyBlogs;
