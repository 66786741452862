import React from 'react';
import DocViewer from "@cyntler/react-doc-viewer";
import Header from "../components/Navbar/Header";
import Footer from "../components/Navbar/Footer";

const docs = [{uri: require("./privacy_policy_famdoc.pdf")}]
const PrivacyPolicy = () => {
    return (
        <>
            <Header/>
            <DocViewer documents={docs}/>
            <Footer/>
        </>
    );
};

export default PrivacyPolicy;
