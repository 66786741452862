import React, {useEffect, useState} from 'react';
import {
    Button,
    Box,
    Icon,
    Spacer,
    Text,
    Select,
    Heading,
    Input,
    Flex,
    FormErrorMessage,
    FormControl, Spinner, TableCaption
} from "@chakra-ui/react";
import {icons} from "../api/icon-mapps";
import {Help} from "@icon-park/react";
import {useParams} from "react-router-dom";
import useCategories from "../hooks/use.categories";
import useAxiosPrivate from "../hooks/use.axios-private";
import {useForm} from "react-hook-form";
import LawyerCard from "../components/LawyerCard/LawyerCard";

const Lawyers = () => {
    const {categoryId} = useParams();
    const {getCategoryByKey} = useCategories();
    const category = getCategoryByKey(categoryId);
    const axiosPrivate = useAxiosPrivate();
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState('NB');
    const [cities, setCities] = useState([]);
    const [lawyers, setLawyers] = useState([]);
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm();

    useEffect(() => {
        const controller = new AbortController();
        const fetchProvinces = async () => {
            try {
                const resp = await axiosPrivate.get('/api/v1/famdoc/location/province_abr/?sort=province', {signal: controller.signal});
                setProvinces(resp.data.results);
            } catch (e) {
                console.log(e);
            }
        }
        fetchProvinces().then();
        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const fetchCities = async () => {
            try {
                const resp = await axiosPrivate.get(`https://api.famdoc.cloud/api/v1/famdoc/location/city/?province_abr=${selectedProvince}&sort=city`, {signal: controller.signal});
                setCities(resp.data.results);
            } catch (e) {
                console.log(e);
            }
        }
        fetchCities().then();
        return () => {
            controller.abort();
        };
    }, [selectedProvince]);

    const onFormSubmit = async (data) => {
        setLoading(true);
        try {
            const resp = await axiosPrivate.get(`/api/v1/famdoc/lawyers/?search=${data.search}&location__province_abr=${data.province_abr}&location__city=${data.city}`)
            setLawyers(resp.data.results);
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }


    return (
        <>

            <Box boxShadow={'md'} p={4} m={1} borderRadius={'md'} bg={'fam.100'} as={'form'} className={'row'}
                 onSubmit={handleSubmit(onFormSubmit)}>
                <div className="col-md-3 col-sm-12">
                    <Heading lineHeight={2.2} as={'h3'} flexGrow={'1'} color={'fam.800'} fontWeight={'700'}
                             fontSize={'20'}>
                        <Icon as={icons[category?.key] ? icons[category.key] : Help} mr={2}/> Lawyers
                    </Heading>
                </div>
                <div className="col-md-3 col-sm-12">
                    <FormControl isInvalid={errors?.province_abr}>
                        <Select
                            placeholder={'Select Province'}
                            {...register('province_abr', {required: true})}
                            variant={'flushed'}
                            onChange={event => setSelectedProvince(event.target.value)}
                        >
                            {
                                provinces.map(item => {
                                        return <option key={item.id} value={item.province_abr}>{item.province}</option>
                                    }
                                )
                            }
                        </Select>
                        <FormErrorMessage>
                            {
                                errors?.province_abr?.type === 'required' ?
                                    'This field is required' :
                                    null
                            }
                        </FormErrorMessage>
                    </FormControl>
                </div>
                <div className="col-md-3 col-sm-12">
                    <FormControl isInvalid={errors?.city}>
                        <Select
                            {...register('city', {required: true})}
                            variant={'flushed'}
                            placeholder={'Select City'}

                        >
                            {
                                cities.map(item => <option key={item.id} value={item.city}>{item.city}</option>)
                            }
                        </Select>
                        <FormErrorMessage>
                            {
                                errors?.city?.type === 'required' ?
                                    'This field is required' :
                                    null
                            }
                        </FormErrorMessage>
                    </FormControl>
                </div>
                <Flex justifyContent={'space-between'} columnGap={5} className="col-md-3 col-sm-12">
                    <FormControl isInvalid={errors?.search}>
                        <Input
                            {...register('search', {required: false})}
                            placeholder={'Search Lawyer'}
                            variant={'flushed'}
                        />
                        <FormErrorMessage>
                            {
                                errors?.search?.type === 'required' ?
                                    'This field is required' :
                                    null
                            }
                        </FormErrorMessage>
                    </FormControl>

                    <Button type={'submit'} colorScheme={'teal'}>Search</Button>
                </Flex>

            </Box>
            <Box mt={5} position={'relative'} minH={'350px'}>
                {
                    loading && <Spinner
                        position={'absolute'}
                        top={'50%'}
                        left={'50%'}
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='fam.100'
                        color='fam.800'
                        size='xl'
                    />
                }
                {
                    (!loading && lawyers.length === 0) && (
                        <Flex mt={30} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                            <img
                                width="150"
                                src={`${process.env.PUBLIC_URL}/assets/images/not-found.gif`}
                                alt="no-data"
                            />
                            <Text fontSize={'20'} fontWeight={'700'} color={'fam.800'}>Search Lawyers!</Text>
                        </Flex>
                    )
                }
                <Box rowGap={4} as={'div'} className={'row'}>
                    {
                        lawyers.map(item => {
                            return (
                                <Box key={item.id} as={'div'} className={'col-md-4 col-sm-12'}>
                                    <LawyerCard {...item} />
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        </>
    );
};

export default Lawyers;
