import React from 'react';
import {FormControl, FormErrorMessage, Input} from "@chakra-ui/react";

const InputField = ({errors, register, type, name, placeholder, options, variant='outline', className='form-control'}) => {
    return (
        <FormControl isInvalid={errors[name]}>
            <Input
                type={type}
                accept={"image/jpeg, image/png"}
                variant={variant}
                className={className}
                multiple={type === 'file' ? false: 'none'}
                placeholder={placeholder}
                {...register(name, options)}
            />
            <FormErrorMessage>
                {
                    errors[name]?.type === 'required' ?
                        'This field is required' :
                        errors[name]?.type === 'maxLength' ?
                            `This field should be less than or equal to ${options.maxLength} characters` :
                            errors[name]?.message
                }
            </FormErrorMessage>
        </FormControl>
    );
};

export default InputField;
