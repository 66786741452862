import React, {useEffect, useState} from 'react';

const About = () => {
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/about-bg.png)`
    });

    return (
        <section id="/about" className="about" style={bgImg}>
            <div className="about-decor">
                <div className="about-circle1">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/team1.png`} alt="team1"/>
                </div>
                <div className="about-circle2">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`} alt="banner1"/>
                </div>
            </div>
            <div className="container">
                <div className="row ">
                    <div className="col-md-5">
                        <div className="about-contain">
                            <div>
                                <h2 className="title">
                                    About The <span>Famdoc Platform</span>
                                </h2>
                                <p className="caption-about">
                                    Famdoc is a high performing case building platform that provides its users the
                                    ability to
                                    Organize and build their case for now or in the future. This powerful tool can help
                                    ensure
                                    that its users are ready for any court action for or against them now or in the
                                    future.
                                    This powerful tool is so easy to use and makes it easy to keep track of all
                                    documentation for the long term.
                                </p>
                                <div className="row sm-mb">
                                    <div className="col-6">
                                        <ul className="about-style">
                                            <li className="abt-hover">
                                                <div className="about-icon">
                                                    <div className="icon-hover">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon1.png`}
                                                             alt="easy-to-customized"/>
                                                    </div>
                                                </div>
                                                <div className="about-text">
                                                    <h3>Easy To Use</h3>
                                                </div>
                                            </li>
                                            <li className="abt-hover">
                                                <div className="about-icon">
                                                    <div className="icon-hover">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon3.png`}
                                                             alt="easy-to-use"/>
                                                    </div>
                                                </div>
                                                <div className="about-text">
                                                    <h3>Organized</h3>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <ul className="about-style">
                                            <li className="abt-hover">
                                                <div className="about-icon">
                                                    <div className="icon-hover">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon2.png`}
                                                             alt="Awasome-Design"/>
                                                    </div>
                                                </div>
                                                <div className="about-text">
                                                    <h3>Clean Design</h3>
                                                </div>
                                            </li>
                                            <li className="abt-hover">
                                                <div className="about-icon">
                                                    <div className="icon-hover">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon4.png`}
                                                             alt="SEO-Friendly"/>
                                                    </div>
                                                </div>
                                                <div className="about-text">
                                                    <h3>Secure</h3>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="top-margin">
                                    <button
                                        type="button"
                                        className="btn btn-custom theme-color theme-color"
                                    >
                                        view more
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 d-medium-none">
                        <div className="about-right">
                            <div className="about-phone">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/landingImg/2.png`}
                                     className="img-fluid" alt="aboutus"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
