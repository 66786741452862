import React, {useEffect, useState} from 'react';
import Tilt from "react-parallax-tilt"
import About from "../components/About/About";
import Feature from "../components/Feature/Feature";
import Team from "../components/Team/Team";
import AppImages from "../components/AppImages/AppImages";
import Testimonial from "../components/Testimonial/Testimonial";
import Contact from "../components/Contact/Contact";
import {useLocation} from "react-router-dom";
import Header from "../components/Navbar/Header";
import Footer from "../components/Navbar/Footer";
import SEO from "../components/SEO/SEO";

const Home = () => {
    const {pathname} = useLocation();
    useEffect(() => {
        const element = document.getElementById(pathname);
        element.scrollIntoView()
    }, [pathname]);


    /*For changing background image by changing the color*/
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/1.png)`
    });


    return (
        <>
            <SEO title={'FamDoc'} description={'FamDoc for separated parents'} type={'website'} />
            <Header/>
            <section id="/" className="home" style={bgImg}>
                <div className="home-decor">
                    <div className="home-circle1">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner3.png`} alt="main-banner3.png"/>
                    </div>
                    <div className="home-circle2">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner12.png`} alt="main-banner12.png"/>
                    </div>
                    <div className="home-circle3">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`} alt="main-banner1.png"/>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="home-contain">
                                <div>

                                    <h4>Document Everything</h4>
                                    <h1>
                                        <span className="f-bold">F</span>A
                                        <span className="f-bold">M</span>
                                        <span className="f-bold f-color">DOC</span>
                                    </h1>
                                    <p>
                                        Once you have your account you have both the website dashboard and the app.
                                        This makes it simple to add documents as you go with your phone or your
                                        computer.
                                        FamDoc has made it as simple as possible to begin building your case today.
                                        Add text messages pictures videos and more from your phone directly to your app
                                        and you can access them at any time on any device. The dashboard makes it easy
                                        to
                                        organize yourself for court or to prepare just encase for the future.</p>
                                    <div style={{display: 'inline-flex'}}>
                                        <a href="https://apps.apple.com/pk/app/famdoc/id1603483940">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`}
                                                 alt="appstore" className="store"/>
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.famdoc&hl=en&gl=US&pli=1">
                                            <img
                                                className="ml-10 store"
                                                src={`${process.env.PUBLIC_URL}/assets/images/play-store.png`}
                                                alt="play-store"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 offset-md-1">
                            <div className="home-right">
                                <Tilt
                                    options={{
                                        perspective: 110,
                                        speed: 400,
                                        max: 1.2,
                                        scale: 1,
                                    }}
                                >
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/landingImg/4.png`}
                                         className="img-fluid" alt="mobile"/>
                                </Tilt>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <About/>
            <Feature/>
            <AppImages/>
            <Team/>
            <Testimonial/>
            <Contact/>
            <Footer/>
        </>
    );
};

export default Home;
