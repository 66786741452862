import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import useCategories from "../hooks/use.categories";
import useDocument from "../hooks/use.document";
import useAxiosPrivate from "../hooks/use.axios-private";
import DocViewer from "@cyntler/react-doc-viewer";
import {Box, Button, Flex, Icon, Spacer, Spinner, Text} from "@chakra-ui/react";
import {icons} from "../api/icon-mapps";
import {Help} from "@icon-park/react";
import {FiPrinter} from "react-icons/fi";
import DocRenderer from "./DocRenderer";

const DocPreviewer = () => {
    const {categoryId, documentId} = useParams();
    const {getCategoryByKey} = useCategories();
    const {document: doc} = useDocument();
    const axiosPrivate = useAxiosPrivate();
    const category = getCategoryByKey(categoryId);
    const [docs, setDocs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [generating, setGenerating] = useState(false);

    const controller = new AbortController();

    const fetchFiles = async () => {
        try {
            setLoading(true);
            const resp = await axiosPrivate.get(`/api/v1/famdoc/files/?document=${documentId}`, {signal: controller.signal});
            setDocs(resp.data.results);
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchFiles().then();
        return () => {
            if (loading) {
                controller.abort();
            }
            setDocs([])
        };
    }, [documentId]);
    return (
        <div>
            <Flex bg={'fam.100'} p={4} borderRadius={'md'} alignItems={'bold'}>
                <Text
                    alignItems={'center'}
                    as={Link}
                    to={`/docs/${categoryId}`}
                    color={'fam.800'}
                    _hover={{color: 'fam.800'}}
                    fontWeight={'700'}
                    fontSize={'20'}
                >
                    <Icon as={icons[category?.key] ? icons[category.key] : Help} mr={2} />{category?.name}
                </Text>
                <Spacer/>
            </Flex>
            <Box mt={5}>
                {
                    loading && <Spinner
                        position={'absolute'}
                        top={'50%'}
                        left={'50%'}
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='fam.100'
                        color='fam.800'
                        size='xl'
                    />
                }
                <DocViewer documents={docs.map(item => {return {uri: item.filepath}})}/>
            </Box>
        </div>
    );
};

export default DocPreviewer;
